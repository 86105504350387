import analytics from '@capturi/analytics'

export enum Event {
  DataTab_Clicked = 'analytics_data-tab_clicked',
  DataGrid_ViewUserConversations = 'analytics_data-grid_ViewUserConversations',
  DataGrid_ViewTeamConversations = 'analytics_data-grid_ViewTeamConversations',
}

export function logEvent(
  event: Event,
  data: Record<string, string | number> = {},
): void {
  analytics.event(event, data)
}
